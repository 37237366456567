import React from 'react';
import { NavLink } from 'react-router-dom';
import { getUserAccountNavigation } from '../../lib/helper';

function AccountNavigation({ username }) {
  return (
    <div className="list-group">
      {getUserAccountNavigation(username).map((nav, index) => (
        <NavLink key={index} className="list-group-item" to={nav.link}>
          {nav.label}
        </NavLink>
      ))}
    </div>
  );
}

export default AccountNavigation;
