import React from "react";
import { itemsApiParams } from "../../../store/shared/services/apiEndpoints";
import Items from "../productList/items";

const RelatedProducts = ({ itemId, category_id }) => {
  return (
    <section className="mt-4 related-items">
      <hr />
      <h4 className="mb-3">Related Items</h4>
      <Items
        apiParams={itemsApiParams({
          category_id,
          similar_to: itemId,
          include_meta: 0,
          page_size: 8,
        })}
        renderEmptyListText={() => "No Related Items Found"}
      />
    </section>
  );
};
export default React.memo(RelatedProducts);
