import React from 'react';
import FeaturedCategories from '../featuredCategories';
import MetaTags from '../MetaTags';
import apiService from '../../../store/shared/services/apiService';
import { getProductUrl } from '../../../store/shared/services/linkCreatorService';
import { getItemAttributeValue, fbFireTrackEvent } from '../../lib/helper';
import { getJSONLD } from '../../../store/shared/lib/jsonld';
import { PRODUCT_CATEGORY } from '../../../store/shared/pageTypes';
import Loading from '../loading';

const HomeCategories = (props) => (
  <>
    <FeaturedCategories />
  </>
);

class HomeCategories1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoriesData:
        (this.props.preloadedData && this.props.preloadedData.item) || null,
      loading: !this.props.preloadedData,
    };
  }

  toggleLoading = (flag = false) => {
    this.setState({ loading: flag });
  };

  render() {
    console.log('home categories', this.state.categoriesData);
    return (
      <>
        <FeaturedCategories preloadedData={{ ...this.state.categoriesData }} />
      </>
    );
  }
}

export default HomeCategories;
