// import React from 'react';
import ListHOC from '../../hoc/listHoc';
import featuredCategoyItem from './featuredCategoyItem';

// const FeaturedCategories = () => {
//     return (
//         <div>

//         </div>
//     )
// }

const FeaturedCategories = ListHOC({
  endpoint: 'categories/featured',
  type: 'grid',
  columnSize: 3,
  pageSize: 12,
})(featuredCategoyItem);

export default FeaturedCategories;
