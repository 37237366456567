import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
const FeaturedCategoyItem = ({ item }) => {
  return (
    <Link to={`/products/${item._id}`}>
      <div className='card text-white text-center'>
        <LazyLoad height={200}>
          <img
            className='img-thumbnail'
            src={
              item.image
                ? item.image.thumbnail
                : 'https://via.placeholder.com/150'
            }
            alt={item.name}
            title={item.name}
          />
        </LazyLoad>
        {/* <img
          className='card-img'
          src={item.image ? item.image.thumbnail : 'https://via.placeholder.com/150'}
          alt={item.name}
        /> */}
        <div className='card-img-overlay'>
          <h5 className='card-title'>{item.name}</h5>
        </div>
      </div>
    </Link>
  );
};

export default withRouter(FeaturedCategoyItem);
