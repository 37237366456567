import React from 'react';
import { withRouter } from 'react-router-dom';
import Items from '../productList/items';
import { itemsApiParams } from '../../../store/shared/services/apiEndpoints';
// import HorizontalAd from '../ads/HorizontalAd';

const RecentItems = (props) => (
  <>
    <h3 className="display-5 text-center mb-3">Trending</h3>
    <Items
      apiParams={itemsApiParams({ page_size: 8, page: 1, include_meta: 0 })}
      columnSize={3}
    />
    <div className="d-flex mt-2 mb-2 justify-content-center">
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => props.history.push('/search')}
      >
        See more
      </button>
    </div>
    {/* <HorizontalAd slot="8419375872" /> */}
  </>
);

export default withRouter(RecentItems);
