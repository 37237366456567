import React from 'react';
import ListHOC from '../../hoc/listHoc';
import { userFeedbackEnpoint } from '../../../store/shared/services/apiEndpoints';
import FeedbackItem from './feedbackItem';
import { compose } from 'redux';

const FeedbackHOC = WrappedComponent => {
  const Container = props => {
    const params = props.rating ? { rating: [props.rating] } : {};
    return (
      <WrappedComponent
        {...props}
        apiParams={{
          endpoint: userFeedbackEnpoint(props.username),
          params: params
        }}
      />
    );
  };
  return Container;
};
const Feedback = compose(
  FeedbackHOC,
  ListHOC({ defaultMessage: 'No Feedback found', type: 'list' })
)(FeedbackItem);
export default Feedback;
