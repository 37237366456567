import React, { useContext } from 'react';
import Tabs from '../tabs/tabs';
import { StateContext } from '../../../store/shared/context';
import { itemsApiParams } from '../../../store/shared/services/apiEndpoints';
import Items from '../productList/items';
import { tabObject } from '../../lib/helper';

const tabArray = [
  tabObject('Available', false, { state: 'available' }),
  tabObject('Sold', false, { state: 'sold' }),
  tabObject('Expired', true, { state: 'expired' }),
  tabObject('Removed', true, { state: 'removed' }),
];
const SellerItems = (props) => {
  const {
    user: { username },
  } = useContext(StateContext);
  const isOwner = props.username === username;

  return (
    <>
      <Tabs>
        {tabArray.map((tab) => {
          if (tab.ownerAccessRequired && !isOwner) {
            return undefined;
          }
          return (
            <div key={tab} label={tab.label}>
              <Items
                {...props}
                apiParams={itemsApiParams({
                  seller: [props.username],
                  ...tab.params,
                })}
              />
            </div>
          );
        })}
      </Tabs>
    </>
  );
};

export default SellerItems;
