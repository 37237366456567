import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { formatDate } from '../../lib/helper';
import seller_avatar from '../../assets/images/defaultSellerAvatar.webp';

const FeedbackItem = ({ item }) => {
  const renderRatingIcon = (rating) => {
    if (rating === 'positive') {
      return <FontAwesomeIcon icon="check-circle" color="green" />;
    }
    if (rating === 'negative') {
      return <FontAwesomeIcon icon="times-circle" color="red" />;
    }
    return <FontAwesomeIcon icon="minus-circle" />;
  };
  return (
    <div className="card mb-1">
      <div className="card-body">
        <div className="row ">
          <div className="col-sm-2">
            <Link to={`/account/${item.reviewer.username}`}>
              <img
                className="rounded-circle"
                style={{ height: 60, width: 60 }}
                src={
                  item.reviewer.avatar ? item.reviewer.avatar : seller_avatar
                }
                // src='https://images.sidelineswap.com/production/002/880/564/2233b5b8b450489f_thumb.jpeg'
                alt={item.username}
              />
            </Link>
          </div>
          <div className="col-10 ">
            <div className="float-left">
              <div className={`review-type-${item.rating}`}>
                {renderRatingIcon(item.rating)}
                <Link to={`/account/${item.reviewer.username}`}>
                  <b className="reviewer_name">{item.reviewer.username}</b>
                </Link>
              </div>
              <div>{item.message}</div>
            </div>
            <div className="float-right">{formatDate(item.created_at)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackItem;
