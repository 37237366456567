import React from 'react';
import UserAccountContainer from '../components/userAccount/userAccountTabs';
import apiService from '../../store/shared/services/apiService';
import Loading from '../components/loading';
import SellerDetails from '../components/productDetails/sellerDetails';
import { API_ENDPOINT_GET_SELLER_DETAILS } from '../../store/shared/services/apiEndpoints';
import { fbFireTrackEvent } from '../lib/helper';
import MetaTags from '../components/MetaTags';

class UserAccount extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      seller:
        (this.props.preloadedData && this.props.preloadedData.seller_profile) ||
        null,
      loading: !this.props.preloadedData,
    };
  }

  toggleLoading = (flag = false) => {
    this.setState({ loading: flag });
  };

  componentDidUpdate(prevProps) {
    if (this.props.match.params.username !== prevProps.match.params.username) {
      this.fetchData();
    }
  }

  componentDidMount = () => {
    if (
      !this.state.seller ||
      this.state.seller.username !== this.props.match.params.username
    ) {
      console.log('data not found, fetching now');
      this.fetchData();
    }
    console.log('data is present');
  };

  fireFbTrackEvent = () => {
    const { seller = {} } = this.state;
    fbFireTrackEvent(seller, 'ViewContent');
  };

  fetchData = () => {
    this.toggleLoading(true);
    apiService
      .request(
        'GET',
        `${API_ENDPOINT_GET_SELLER_DETAILS}/${this.props.match.params.username}`
      )
      .then(
        (resp) => {
          this.setState({
            seller: resp.data.seller_profile,
          });
        },
        (err) => {}
      )
      .finally(() => {
        this.toggleLoading(false);
      });
  };

  render() {
    const { seller } = this.state;

    if (this.state.loading) {
      return <Loading />;
    }
    if (!seller) {
      return '';
    }
    return (
      <>
        <MetaTags
          title={`${seller?.username} - PC Swaps Seller`}
          description={`Shop ${seller.username}'s seller page to find the best deals on new and used hardware or create your PC Swaps to list your hardware for free.`}
          ogDescription={`Shop ${seller.username}'s seller page to find the best deals on new and used hardware or create your PC Swaps to list your hardware for free.`}
          ogTitle={`${seller?.username} - PC Swaps Seller`}
        />
        <SellerDetails {...this.state} isProfile />
        <UserAccountContainer {...this.props} />
      </>
    );
  }
}
export default UserAccount;
