import React, { useEffect, useState } from 'react';
import AccountNavigation from './accountNavigation';
import Favorites from '../favorites';
// import Feedback from '../components/feedback';
import SellerItems from '../seller/sellerItems';
import SellerFeedback from '../seller/sellerFeedback';
// import { API_ENDPOINT_GET_SELLER_DETAILS } from '../../store/shared/services/apiEndpoints';
// import apiService from '../../store/shared/services/apiService';

const UserAccountContainer = (props) => {
  const { type, username } = props.match.params;
  // TO DO: Show seller details on account page
  // const [sellerDetails, setSellerDetails] = useState({});
  // const [userImage, setUserImage] = useState(`${avatar}?${new Date()}`);
  // useEffect(() => {
  //   apiService
  //     .request('GET', `${API_ENDPOINT_GET_SELLER_DETAILS}/${username}`)
  //     .then((resp) => {
  //       setSellerDetails(resp.data);
  //     });
  // }, [username]);

  const renderAccountContainer = () => {
    // const { type } = props.match.params;
    switch (type) {
      case 'favorites':
        return <Favorites {...props} username={username} />;
      case 'feedback':
        return <SellerFeedback {...props} username={username} />;
      default:
        // return <Items {...props} apiParams={itemsApiParams({ seller: [username] })} />;
        return <SellerItems username={username} {...props} />;
    }
  };

  // useEffect(() => console.log(avatar), [avatar]);

  return (
    <section className="container py-2">
      {/* <hr /> */}
      <div className="row">
        <div className="col-sm-3">
          <AccountNavigation username={username} />
        </div>
        <div className="col-sm-9">{renderAccountContainer()}</div>
      </div>
    </section>
  );
};

// const mapStateToProps = (state) => ({ avatar: state.user.avatar });

// export default connect(mapStateToProps, { setUserAvatar })(
//   UserAccountContainer
// );

export default UserAccountContainer;
