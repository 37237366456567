import React from 'react';
import { tabObject } from '../../lib/helper';
import Tabs from '../tabs/tabs';
import Feedback from '../feedback';

const tabArray = [
  tabObject('All', false, {}),
  tabObject('Positive', false, { rating: 'positive' }),
  tabObject('Neutral', false, { rating: 'neutral' }),
  tabObject('Negative', false, { rating: 'negative' })
];
const SellerFeedback = props => {
  const { username } = props;
  return (
    <Tabs>
      {tabArray.map(tab => (
        <div key={tab.label} label={tab.label}>
          <Feedback {...props} username={username} {...tab.params} />
        </div>
      ))}
    </Tabs>
  );
};

export default SellerFeedback;
